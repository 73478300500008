import React from 'react'
import './Home.css';
import { Container, Row, Col } from "react-bootstrap";
import WebBanner from "../Assets/home/mainBanner.png";
import Smiley from "../Assets/home/Smiley.svg";
import SmileyFlip from "../Assets/home/SmileyFlip.svg";
import Mumbai from "../Assets/home/mum_thumb.png";
import Kolkata from "../Assets/home/kol_thumb.png";
import Delhi from "../Assets/home/del_thumb.png";
import Car from "../Assets/home/Car.png";
import Sand from "../Assets/home/Sand.svg";
import FerrisWheel from "../Assets/home/wheel.png";
import footerLogoNH from "../Assets/home/footerLogo.svg";
import NodwinLogo from "../Assets/home/NodwinLogo.svg";
import facebook from "../Assets/home/social/facebook.svg";
import twitter from "../Assets/home/social/twitter.svg";
import instagram from "../Assets/home/social/instagram.svg";
import youtube from "../Assets/home/social/youtube.svg";
import sandFooter from "../Assets/home/sandFooter.svg";
import CarAnimate from "../Assets/home/carAnimate.png";
import bangalore from "../Assets/citytakeovers/banglore.png";
import chandigarh from "../Assets/citytakeovers/chandigarh.png";
import chennai from "../Assets/citytakeovers/chennai.png";
import guwahati from "../Assets/citytakeovers/guwahati.png";
import hyderabad from "../Assets/citytakeovers/hyderabad.png";
import jaipur from "../Assets/citytakeovers/jaipur.png";
import mumbai from "../Assets/citytakeovers/mumbai.png";
import goa from "../Assets/citytakeovers/goa.png";
import lineUpBanner from "../Assets/lineup/lineupBanner.png";
import KolkataLineUp from "../Assets/lineup/KolkataLineUp.svg";
import KolkataLineUpM from "../Assets/lineup/KolkataLineUpM.svg";
import DelhiLineUp from "../Assets/lineup/DelhiLineUp.svg";
import TagLineUp from "../Assets/lineup/TagLineUp.svg";
import Sparkle from "../Assets/lineup/sparkle.svg";
import Lotus from "../Assets/lineup/lotus.svg";
import LotusR from "../Assets/lineup/lotusR.svg";
import SmileyLine from "../Assets/lineup/smileyLine.svg";
import guidelineFestival from "../Assets/guidelineFestival.png";

import DelhiNCR from "../Assets/citytakeovers/delhiCityTakeover.png";
import kolkataCT from "../Assets/citytakeovers/kolkatacityTakeover.png";
import shillongCT from "../Assets/citytakeovers/shillongcityTakeover.png";
import P91 from '../Assets/dayTwo/91.png';
import P92 from '../Assets/dayTwo/92.png';
import P03 from '../Assets/dayTwo/03.png';
import P04 from '../Assets/dayTwo/04.png';

import P02 from '../Assets/dayOne/02-min.jpg';
import P06 from '../Assets/dayOne/06-min.jpg';
import P08 from '../Assets/dayOne/08-min.jpg';
import P05 from '../Assets/dayOne/05-min.jpg';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import { Gallery, Item } from 'react-photoswipe-gallery';

function Home() {
    return (
        <div>
            <section className="NH7Banner">

                <div className='relativeStyle'>
                    <a href='https://insider.in/bacardi-nh7-weekender-pune-2022/event' target='_blank' className='fixedStyle'>Buy Tickets</a>
                    <br></br>
                    <a href="#" rel="noopener noreferrer">
                        <img src={WebBanner} className="webBannerStyle" alt="NH7 Weekender 2022 Banner"></img>
                    </a>
                </div>
            </section>
            <section className="cityMainDiv">
                <Container fluid>
                    <div data-aos="fade-down" data-aos-duration="3000" className="alignLeft">
                        <img  src={Smiley} className="animateSmiley" alt="NH7 - Smiley"></img>
                    </div>
                    
                    <div className="contentDiv">
                        <h1 data-aos="fade" data-aos-duration="2000" className="cityHeadMain">THE HAPPIEST MUSIC FESTIVAL IS BACK ON GROUND</h1>
                        <p data-aos="fade" data-aos-duration="2000" className="citySubHead">We had a blast in Pune at BACARDÍ NH7 Weekender this year; from the music, to the food, ferris wheel, to meeting our Weekender Warriors again!
                        And we’re not done yet! The Happiest Music is travelling to your city in a limited capacity, with music, food, art, dance and a lot of feisty cocktails. Get ready to #DoWhatMakesYouHappyAgain 
                        </p>
                    </div>
                    <div className="cityCard"> 
                        <Row>
                            <Col data-aos="fade-up" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                                <div className="cityCardStyle">
                                    <img className="cityCardImg" src={Delhi} alt="NH& WEEKENDER - Delhi"></img>
                                    <div className="spaceBtn">
                                        <a href="https://insider.in/bacardi-nh7-weekender/article" rel="noopener noreferrer" target="_blank" className="visitBtn">KNOW MORE</a>
                                    </div>
                                </div>
                            </Col>
                            <Col data-aos="fade-up" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                                <div className="cityCardStyle">
                                    <img className="cityCardImg" src={Kolkata} alt="NH& WEEKENDER - Kolkata"></img>
                                    <div className="spaceBtnMiddle">
                                        <a href="https://insider.in/bacardi-nh7-weekender/article" rel="noopener noreferrer" target="_blank" className="visitBtn">KNOW MORE</a>
                                    </div>
                                </div>
                            </Col>
                            <Col data-aos="fade-up" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                                <div className="cityCardStyle">
                                    <img className="cityCardImg" src={Mumbai} alt="NH& WEEKENDER - Mumbai"></img>
                                    <div className="spaceBtn">
                                        <a href="https://insider.in/bacardi-nh7-weekender/article" rel="noopener noreferrer" target="_blank" className="visitBtn">KNOW MORE</a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div>
                    </div>
                </Container>
                <div className="bottomProp">
                    <div className="alignLeftPropCity">
                        <img className="propsSecond" src={Sand} alt="Props - Sand"></img>
                    </div>
                    <div className="carSecond carSecond-animation">
                        <img className="propsSecond" src={Car} alt="Props - Car"></img>
                    </div>
                </div>
            </section>
            {/* <section className="cityTakeoversDiv">
                <Container fluid className="spaceBottomSecond">
                <div className="cityTakeoverContent">
                    <h1 data-aos="fade-down" data-aos-duration="3000" className="ctHeading">CITY TAKEOVERS</h1>
                    <p data-aos="zoom-in" data-aos-duration="3000" className="ctSubHead">A mini-festival pop-up at the closest cultural club in your city. Your favourite artists. Same vibe. So put your festival look together and get ready to "Do What Makes You Happy Again"!</p>
                </div>
                <div className="desktopViewCity">
                    <Row>
                        <Col data-aos="zoom-in" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                            <a href="#" rel="noopener noreferrer" className='hvrStyleTxt'>
                                <img className="CardheightAdjust" src={goa} alt="city takeovers - goa"></img>
                                <p className='cityTakeoversDA'>2nd & 3rd April</p>
                            </a>
                        </Col>
                        <Col data-aos="zoom-in" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                        <a href="#" className='hvrStyleTxt' rel="noopener noreferrer">
                            <img className="CardheightAdjust" src={hyderabad} alt="city takeovers - hyderabad"></img>
                            <p className='cityTakeoversDA'>11th & 12th March</p>
                        </a>  
                        </Col>
                        <Col data-aos="zoom-in" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                        <a href="#" className='hvrStyleTxt' rel="noopener noreferrer">
                            <img className="CardheightAdjust" src={jaipur} alt="city takeovers - jaipur"></img>
                            <p className='cityTakeoversDA'>11th & 12th March</p>
                        </a>
                        </Col>
                    </Row>
                    <Row>
                        <Col data-aos="zoom-in" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                            <a href="#" className='hvrStyleTxt' rel="noopener noreferrer">
                                <img className="CardheightAdjust" src={bangalore} alt="city takeovers - bangalore"></img>
                                <p className='cityTakeoversDA'>Dates to be announced soon</p>
                            </a>
                        </Col>
                        <Col data-aos="zoom-in" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                            <a href="#" className='hvrStyleTxt' rel="noopener noreferrer">
                                <img className="CardheightAdjust" src={chandigarh} alt="city takeovers - chandigarh"></img>
                                <p className='cityTakeoversDA'>Dates to be announced soon</p>
                            </a>
                        </Col>
                        <Col data-aos="zoom-in" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                            <a href="#" className='hvrStyleTxt' rel="noopener noreferrer">
                                <img className="CardheightAdjust" src={chennai} alt="city takeovers - chennai"></img>
                                <p className='cityTakeoversDA'>Dates to be announced soon</p>
                            </a>
                        </Col>
                    </Row>
                    <Row>
                    <Col data-aos="zoom-in" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                        <a href="#" className='hvrStyleTxt' rel="noopener noreferrer">
                                <img className="CardheightAdjust" src={shillongCT} alt="city takeovers - shillong"></img>
                                <p className='cityTakeoversDA'>Dates to be announced soon</p>
                            </a>
                        </Col>
                        <Col data-aos="zoom-in" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                            <a href="#" className='hvrStyleTxt' rel="noopener noreferrer">
                                <img className="CardheightAdjust" src={guwahati} alt="city takeovers - guwahati"></img>
                                <p className='cityTakeoversDA'>Dates to be announced soon</p>
                            </a>
                        </Col>
                        <Col data-aos="zoom-in" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                            <a href="#" className='hvrStyleTxt' rel="noopener noreferrer">
                                <img className="CardheightAdjust" src={kolkataCT} alt="city takeovers - Kolkata"></img>
                                <p className='cityTakeoversDA'>Dates to be announced soon</p>
                            </a>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} xs={12} md={2} lg={2} xl={2}>
                        </Col>
                        <Col data-aos="zoom-in" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                        <a href="#" className='hvrStyleTxt' rel="noopener noreferrer">
                                <img className="CardheightAdjust" src={DelhiNCR} alt="city takeovers - delhi"></img>
                                <p className='cityTakeoversDA'>Dates to be announced soon</p>
                            </a>
                        </Col>
                        <Col data-aos="zoom-in" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                            <a href="#" className='hvrStyleTxt' rel="noopener noreferrer">
                                <img className="CardheightAdjust" src={mumbai} alt="city takeovers - mumbai"></img>
                                <p className='cityTakeoversDA'>Dates to be announced soon</p>
                            </a>
                        </Col>
                        <Col sm={12} xs={12} md={2} lg={2} xl={2}>
                        </Col>
                    </Row>
                    </div>
                    <div className="mobileViewCity">
                    <Row>
                    <Col data-aos="zoom-in" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                            <a href="#" rel="noopener noreferrer">
                                <img className="CardheightAdjust" src={goa} alt="city takeovers - goa"></img>
                                <p className='cityTakeoversDAM'>2nd & 3rd April</p>
                            </a>
                        </Col>
                        <Col data-aos="zoom-in" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                        <a href="#" rel="noopener noreferrer">
                            <img className="CardheightAdjust" src={hyderabad} alt="city takeovers - hyderabad"></img>
                            <p className='cityTakeoversDAM'>11th & 12th March</p>
                        </a>
                        <a href="#" rel="noopener noreferrer">
                            <img className="CardheightAdjust" src={jaipur} alt="city takeovers - jaipur"></img>
                            <p className='cityTakeoversDAM'>11th & 12th March</p>
                        </a>
                           
                        </Col>
                        
                        <Col data-aos="zoom-in" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                            
                        </Col>
                    </Row>
                    <Row>
                        <Col data-aos="zoom-in" data-aos-duration="3000" sm={12} xs={12} md={3} lg={3} xl={3}>
                            <a href="#" rel="noopener noreferrer">
                                <img className="CardheightAdjust" src={bangalore} alt="city takeovers - bangalore"></img>
                                <p className='cityTakeoversDAM'>Dates to be announced soon</p>
                            </a>
                        </Col>
                        <Col data-aos="zoom-in" data-aos-duration="3000" sm={12} xs={12} md={3} lg={3} xl={3}>
                            <a href="#" rel="noopener noreferrer">
                                <img className="CardheightAdjust" src={chandigarh} alt="city takeovers - chandigarh"></img>
                                <p className='cityTakeoversDAM'>Dates to be announced soon</p>
                            </a>
                        </Col>
                        <Col data-aos="zoom-in" data-aos-duration="3000" sm={12} xs={12} md={3} lg={3} xl={3}>
                            <a href="#" rel="noopener noreferrer">
                                <img className="CardheightAdjust" src={chennai} alt="city takeovers - chennai"></img>
                                <p className='cityTakeoversDAM'>Dates to be announced soon</p>
                            </a>
                        </Col>
                        
                    </Row>
                    <Row>
                    <Col data-aos="zoom-in" data-aos-duration="3000" sm={12} xs={12} md={3} lg={3} xl={3}>
                        <a href="#" rel="noopener noreferrer">
                                <img className="CardheightAdjust" src={shillongCT} alt="city takeovers - shillong"></img>
                                <p className='cityTakeoversDAM'>Dates to be announced soon</p>
                            </a>
                        </Col>
                        <Col data-aos="zoom-in" data-aos-duration="3000" sm={12} xs={12} md={3} lg={3} xl={3}>
                            <a href="#" rel="noopener noreferrer">
                                <img className="CardheightAdjust" src={guwahati} alt="city takeovers - guwahati"></img>
                                <p className='cityTakeoversDAM'>Dates to be announced soon</p>
                            </a>
                        </Col>
                        <Col data-aos="zoom-in" data-aos-duration="3000" sm={12} xs={12} md={3} lg={3} xl={3}>
                        <a href="#" rel="noopener noreferrer">
                            <img className="CardheightAdjust" src={kolkataCT} alt="city takeovers - Kolkata"></img>
                            <p className='cityTakeoversDAM'>Dates to be announced soon</p>
                        </a>
                        </Col>
                        <Col data-aos="zoom-in" data-aos-duration="3000" sm={12} xs={12} md={3} lg={3} xl={3}>
                            <a href="#" rel="noopener noreferrer">
                                <img className="CardheightAdjust" src={DelhiNCR} alt="city takeovers - delhi"></img>
                                <p className='cityTakeoversDAM'>Dates to be announced soon</p>
                            </a>
                        </Col>
                        <Col data-aos="zoom-in" data-aos-duration="3000" sm={12} xs={12} md={3} lg={3} xl={3}>
                        <a href="#" rel="noopener noreferrer">
                                <img className="CardheightAdjust" src={mumbai} alt="city takeovers - mumbai"></img>
                                <p className='cityTakeoversDAM'>Dates to be announced soon</p>
                            </a>
                        </Col>
                        
                    </Row>
                    </div>
                </Container>
                <div className="bottomGuidelines">
                    <div className="alignRight">
                        <img src={FerrisWheel} className="ferrisWheelG" alt="NH7 Weekender - Ferris Wheel"></img>
                    </div>
                        <div className="car car-animation">
                            <img src={CarAnimate} alt="carAnimate - NH7"/>
                        </div>
                </div>
            </section> */}


            {/* <section className='lineUpDiv'>
                <img src={lineUpBanner} className="webBannerStylel" alt="NH7 Weekender 2022 lineupu8"></img>
            </section> */}

            <section className='photosDiv'>
                <Container fluid>
                    <div className='spacePhotoDiv'>
                        <h1 data-aos="fade-down" data-aos-duration="3000" className="ctHeading">PHOTO GALLERY</h1>
                    </div>

                    <div>
                        <p className='txtCard2'>Pune Day 1 Photos</p>
                    </div>
                    <Gallery shareButton={false}>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P05}
                                    thumbnail={P05}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P05} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P02}
                                    thumbnail={P02}
                                    width="2096"
                                    height="1399"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P02} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P06}
                                    thumbnail={P06}
                                    width="2880"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P06} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P08}
                                    thumbnail={P08}
                                    width="2880"
                                    height="1920"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage" src={P08} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>
                        </Gallery>
                        <div className="spaceBtn">
                            <a href="/dayOne" target='_blank' rel="noopener noreferrer" className="visitBtn">VIEW ALL</a>
                        </div>

                        <br></br><br></br>
                    
                    <div>
                        <p className='txtCard2'>Pune Day 2 Photos</p>
                    </div>
                    <Gallery shareButton={false}>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <Item
                                    original={P91}
                                    thumbnail={P91}
                                    width="1920"
                                    height="1280"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P91} alt=""/>
                                    )}
                                </Item>
                                
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P92}
                                    thumbnail={P92}
                                    width="1920"
                                    height="1281"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P92} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P03}
                                    thumbnail={P03}
                                    width="1920"
                                    height="1281"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P03} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P04}
                                    thumbnail={P04}
                                    width="1920"
                                    height="1281"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage" src={P04} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>
                        </Gallery>
                        <div className="spaceBtn">
                            <a href="/dayTwo" target='_blank' rel="noopener noreferrer" className="visitBtn">VIEW ALL</a>
                        </div>
                </Container>
            </section>


            {/* <section className='lineUpDiv'>
                <img src={guidelineFestival} className="webBannerStylel" alt="NH7 Weekender 2022 guidelineFestival"></img>
            </section> */}

            <section className="guidelinesDiv">
                <Container fluid>
                    <div data-aos="fade-down" data-aos-duration="3000" className="rightSmiley">
                        <img src={SmileyFlip} className="animateSmiley" alt="NH7 - Smiley"></img>
                    </div>
                    <div className="guidelinesTxt">
                        <h1 data-aos="slide-down" data-aos-duration="3000" className="glHeading">Guidelines</h1>
                        <div>
                            <p data-aos="fade-up" data-aos-duration="3000" className="Points">- Limited capacity festival</p>
                            <p data-aos="fade-up" data-aos-duration="3000" className="Points">- Only fully vaccinated ticket holders will be allowed into the venue (Make sure your second dose is taken at least 14 days before the festival)</p>
                            <p data-aos="fade-up" data-aos-duration="3000" className="Points">- Predominantly Indian Lineup</p>
                            <p data-aos="fade-up" data-aos-duration="3000" className="Points">- Weekender Warriors wear their masks</p>
                            <p data-aos="fade-up" data-aos-duration="3000" className="Points">- Thermal scanning is mandatory</p>
                            <p data-aos="fade-up" data-aos-duration="3000" className="Points">- Frequent sanitisation and social distancing is encouraged
                            </p>
                            <p data-aos="fade-up" data-aos-duration="3000" className="Points">- Please read the terms and conditions carefully before purchasing the tickets</p>
                        </div>
                    </div>
                </Container>
                <div className="bottomProp">
                    <div className="alignLeftPropCity">
                        <img className="propsSecond" src={Sand} alt="Props - Sand"></img>
                    </div>
                    <div className="carSecond carSecond-animation">
                        <img className="propsSecond" src={Car} alt="Props - Car"></img>
                    </div>
                </div>
            </section>
            <section className="footerDiv">
                <Container>
                    <Row data-aos="fade" data-aos-duration="3000">
                        <Col sm={12} xs={12} md={4} lg={4} xl={4}>
                        
                        </Col>
                        <Col sm={12} xs={12} md={4} lg={4} xl={4}>
                        <div className="footerLogo">
                                <img src={footerLogoNH} className="footerLogo" alt="NH7 Weekender Logo"></img>
                            </div>
                            <div className="footerSocial">
                                    <a href="https://www.facebook.com/nh7weekender/" rel="noopener noreferrer" target="_blank">
                                        <img src={facebook} className="socialIcon rightIconSpace" alt="facebook - NH7 Weekender"></img>
                                    </a>
                                    <a href="https://twitter.com/NH7?t=UdoIFOETVTQ3jHYNi30EaQ&s=09" rel="noopener noreferrer" target="_blank">
                                        <img src={twitter} className="socialIcon rightIconSpace" alt="twitter - NH7 Weekender"></img>
                                    </a>
                                    <a href="https://www.instagram.com/nh7dotin/" rel="noopener noreferrer" target="_blank">
                                        <img src={instagram} className="socialIcon rightIconSpace" alt="instagram - NH7 Weekender"></img>
                                    </a>
                                    <a href="https://youtube.com/c/nh7dotin" rel="noopener noreferrer" target="_blank">
                                        <img src={youtube} className="socialIcon" alt="youtube - NH7 Weekender"></img>
                                    </a>
                                </div>
                        </Col>
                        <Col sm={12} xs={12} md={4} lg={4} xl={4}>
                            <div className="nodwinFooterLogo">
                                <a href="https://nodwingaming.com/" rel="noopener noreferrer" target="_blank">
                                    <img src={NodwinLogo} className="NodwinfooterLogo" alt="NodwinLogo"></img>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="lastOne">
                <Row>
                    <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                    <div className="leftDisplay">
                            <img data-aos="zoom-in" data-aos-duration="3000" src={FerrisWheel} className="footerFerrisWheel mViewFooter" alt="ferris wheel NH7 Weekender"></img>
                        </div>
                    </Col>
                    <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                        <div>
                            <p className="footerTxt">Copyright © 2021 NH7 WEEKENDER All Rights Reserved. Designed & Developed by Nodwin Gaming</p>
                        </div>
                    </Col>
                    <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                        <img src={sandFooter} className="sandFooterD" alt="sand NH7 Weekender"></img>
                    </Col>
                </Row>
                <div className="bottomPropFooter">
                    <Row>
                        <Col sm={6} xs={6} md={6} lg={6} xl={6}>
                            <div className="alignLeftProp">
                                <img data-aos="zoom-in" data-aos-duration="3000" src={FerrisWheel} className="footerFerrisWheelM" alt="ferris wheel NH7 Weekender"></img>
                            </div>
                        </Col>
                        <Col className="alignRight" sm={6} xs={6} md={6} lg={6} xl={6}>
                        <img src={sandFooter} className="sandFooter" alt="sand NH7 Weekender"></img>
                        </Col>
                    </Row>
                </div>
                </div>
            </section>
        </div>
    )
}

export default Home
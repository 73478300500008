import './App.css';
import Home from './Components/Home.js';
import DayTwo from './Components/dayTwo.js';
import DayOne from './Components/dayOne.js';

import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';


document.addEventListener('contextmenu', event => event.preventDefault());

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home/>}>
          </Route>
          <Route path="/dayTwo" element={<DayTwo/>}>
          </Route>
          <Route path="/dayOne" element={<DayOne/>}>
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

AOS.init();
export default App;

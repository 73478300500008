import React from 'react';
import './dayOne.css';
import { Container, Button, Modal,Row, Col } from "react-bootstrap";
import { propTypes } from 'react-bootstrap/esm/Image';
import arrow from '../Assets/arrow.svg';

import P01 from '../Assets/dayOne/01-min.jpg';
import P02 from '../Assets/dayOne/02-min.jpg';
import P03 from '../Assets/dayOne/03-min.jpg';
import P04 from '../Assets/dayOne/04-min.jpg';
import P05 from '../Assets/dayOne/05-min.jpg';
import P06 from '../Assets/dayOne/06-min.jpg';
import P07 from '../Assets/dayOne/07-min.jpg';
import P08 from '../Assets/dayOne/08-min.jpg';
import P09 from '../Assets/dayOne/09-min.jpg';
import P10 from '../Assets/dayOne/10-min.jpg';
import P11 from '../Assets/dayOne/11-min.jpg';
import P12 from '../Assets/dayOne/12-min.jpg';
import P13 from '../Assets/dayOne/13-min.jpg';
import P14 from '../Assets/dayOne/14-min.jpg';
import P15 from '../Assets/dayOne/15-min.jpg';
import P16 from '../Assets/dayOne/16-min.jpg';
import P17 from '../Assets/dayOne/17-min.jpg';
import P18 from '../Assets/dayOne/18-min.jpg';
import P19 from '../Assets/dayOne/19-min.jpg';
import P20 from '../Assets/dayOne/20-min.jpg';
import P21 from '../Assets/dayOne/21-min.jpg';
import P22 from '../Assets/dayOne/22-min.jpg';
import P23 from '../Assets/dayOne/23-min.jpg';
import P24 from '../Assets/dayOne/24-min.jpg';
import P25 from '../Assets/dayOne/25-min.jpg';
import P26 from '../Assets/dayOne/26-min.jpg';
import P27 from '../Assets/dayOne/27-min.jpg';
import P28 from '../Assets/dayOne/28-min.jpg';
import P29 from '../Assets/dayOne/29-min.jpg';
import P30 from '../Assets/dayOne/30-min.jpg';
import P31 from '../Assets/dayOne/31-min.jpg';
import P32 from '../Assets/dayOne/32-min.jpg';
import P33 from '../Assets/dayOne/33-min.jpg';
import P34 from '../Assets/dayOne/34-min.jpg';
import P35 from '../Assets/dayOne/35-min.jpg';
import P36 from '../Assets/dayOne/36-min.jpg';
import P37 from '../Assets/dayOne/37-min.jpg';
import P38 from '../Assets/dayOne/38-min.jpg';
import P39 from '../Assets/dayOne/39-min.jpg';
import P40 from '../Assets/dayOne/40-min.jpg';
import P41 from '../Assets/dayOne/41-min.jpg';
import P42 from '../Assets/dayOne/42-min.jpg';
import P43 from '../Assets/dayOne/43-min.jpg';
import P44 from '../Assets/dayOne/44-min.jpg';
import P45 from '../Assets/dayOne/45-min.jpg';
import P46 from '../Assets/dayOne/46-min.jpg';
import P47 from '../Assets/dayOne/47-min.jpg';
import P48 from '../Assets/dayOne/48-min.jpg';
import P49 from '../Assets/dayOne/49-min.jpg';
import P50 from '../Assets/dayOne/50-min.jpg';
import P51 from '../Assets/dayOne/51-min.jpg';
import P52 from '../Assets/dayOne/52-min.jpg';
import P53 from '../Assets/dayOne/53-min.jpg';
import P54 from '../Assets/dayOne/54-min.jpg';
import P55 from '../Assets/dayOne/55-min.jpg';
import P56 from '../Assets/dayOne/56-min.jpg';
import P57 from '../Assets/dayOne/57-min.jpg';
import P58 from '../Assets/dayOne/58-min.jpg';
import P59 from '../Assets/dayOne/59-min.jpg';
import P60 from '../Assets/dayOne/60-min.jpg';
import P61 from '../Assets/dayOne/61-min.jpg';
import P62 from '../Assets/dayOne/62-min.jpg';
import P63 from '../Assets/dayOne/63-min.jpg';
import P64 from '../Assets/dayOne/64-min.jpg';
import P65 from '../Assets/dayOne/65-min.jpg';
import P66 from '../Assets/dayOne/66-min.jpg';
import P67 from '../Assets/dayOne/67-min.jpg';
import P68 from '../Assets/dayOne/68-min.jpg';
import P69 from '../Assets/dayOne/69-min.jpg';
import P70 from '../Assets/dayOne/70-min.jpg';
import P71 from '../Assets/dayOne/71-min.jpg';
import P72 from '../Assets/dayOne/72-min.jpg';
import P73 from '../Assets/dayOne/73-min.jpg';
import P74 from '../Assets/dayOne/74-min.jpg';
import P75 from '../Assets/dayOne/75-min.jpg';
import P76 from '../Assets/dayOne/76-min.jpg';
import P77 from '../Assets/dayOne/77-min.jpg';
import P78 from '../Assets/dayOne/78-min.jpg';
import P79 from '../Assets/dayOne/79-min.jpg';
import P80 from '../Assets/dayOne/80-min.jpg';
import P81 from '../Assets/dayOne/81-min.jpg';
import P82 from '../Assets/dayOne/82-min.jpg';
import P83 from '../Assets/dayOne/83-min.jpg';
import P84 from '../Assets/dayOne/84-min.jpg';
import P85 from '../Assets/dayOne/85-min.jpg';
import P86 from '../Assets/dayOne/86-min.jpg';
import P87 from '../Assets/dayOne/87-min.jpg';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import { Gallery, Item } from 'react-photoswipe-gallery';

function dayOne() {
   
    return (
        <div className='dayOneBg'>
             {/* <div className='HomeButton'>
                <a href='/' className='styleBtnhme'>
                    <img src={arrow} className="arrowStyle"></img>
                    Home</a>
            </div> */}
            <section className='spaceBottom'>
                <Container fluid>
                    <div>
                        <p className='txtCard'>Pune Day 1 Photos</p>
                    </div>
                    <div>
                        <Gallery shareButton={false}>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P86}
                                    thumbnail={P86}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P86} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P02}
                                    thumbnail={P02}
                                    width="2096"
                                    height="1399"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P02} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P03}
                                    thumbnail={P03}
                                    width="2880"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P03} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P04}
                                    thumbnail={P04}
                                    width="2880"
                                    height="1920"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage" src={P04} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>

                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P05}
                                    thumbnail={P05}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P05} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P06}
                                    thumbnail={P06}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM " src={P06} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P08}
                                    thumbnail={P08}
                                    width="2880"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P08} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P09}
                                    thumbnail={P09}
                                    width="2880"
                                    height="1920"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage" src={P09} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>

                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P10}
                                    thumbnail={P10}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P10} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P11}
                                    thumbnail={P11}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P11} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P12}
                                    thumbnail={P12}
                                    width="2880"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P12} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P13}
                                    thumbnail={P13}
                                    width="2880"
                                    height="1920"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage" src={P13} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>

                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <Item
                                    original={P01}
                                    thumbnail={P01}
                                    width="1921"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P01} alt=""/>
                                    )}
                                </Item>
                                
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P15}
                                    thumbnail={P15}
                                    width="1276"
                                    height="1916"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P15} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P16}
                                    thumbnail={P16}
                                    width="1276"
                                    height="1916"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P16} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P17}
                                    thumbnail={P17}
                                    width="1276"
                                    height="1916"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage" src={P17} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>

                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <Item
                                    original={P14}
                                    thumbnail={P14}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P14} alt=""/>
                                    )}
                                </Item>

                                
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P19}
                                    thumbnail={P19}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P19} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P20}
                                    thumbnail={P20}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P20} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P21}
                                    thumbnail={P21}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage " src={P21} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>


                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P22}
                                    thumbnail={P22}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P22} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P23}
                                    thumbnail={P23}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P23} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P24}
                                    thumbnail={P24}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P24} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P25}
                                    thumbnail={P25}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage " src={P25} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>

                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P26}
                                    thumbnail={P26}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P26} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P27}
                                    thumbnail={P27}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P27} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P28}
                                    thumbnail={P28}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P28} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P29}
                                    thumbnail={P29}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P29} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>

                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <Item
                                    original={P41}
                                    thumbnail={P41}
                                    width="1277"
                                    height="1916"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P41} alt=""/>
                                    )}
                                </Item>
                                
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <Item
                                    original={P18}
                                    thumbnail={P18}
                                    width="1276"
                                    height="1916"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P18} alt=""/>
                                    )}
                                </Item>

                                
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            
                                <Item
                                    original={P32}
                                    thumbnail={P32}
                                    width="1277"
                                    height="1916"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P32} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <Item
                                    original={P61}
                                    thumbnail={P61}
                                    width="1276"
                                    height="1916"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P61} alt=""/>
                                    )}
                                </Item>

                                
                            </Col>
                        </Row>

                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P34}
                                    thumbnail={P34}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P34} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P35}
                                    thumbnail={P35}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P35} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P36}
                                    thumbnail={P36}
                                    width="2882"
                                    height="1921"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P36} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P37}
                                    thumbnail={P37}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P37} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>

                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P38}
                                    thumbnail={P38}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P38} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P39}
                                    thumbnail={P39}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P39} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P40}
                                    thumbnail={P40}
                                    width="2882"
                                    height="1921"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P40} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <Item
                                    original={P31}
                                    thumbnail={P31}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage" src={P31} alt=""/>
                                    )}
                                </Item>

                                
                            </Col>
                        </Row>

                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P42}
                                    thumbnail={P42}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P42} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P43}
                                    thumbnail={P43}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P43} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P44}
                                    thumbnail={P44}
                                    width="2882"
                                    height="1921"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P44} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P45}
                                    thumbnail={P45}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P45} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>

                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P46}
                                    thumbnail={P46}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P46} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P47}
                                    thumbnail={P47}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P47} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P48}
                                    thumbnail={P48}
                                    width="2882"
                                    height="1921"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P48} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P49}
                                    thumbnail={P49}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P49} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>   


                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P50}
                                    thumbnail={P50}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P50} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P51}
                                    thumbnail={P51}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P51} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P52}
                                    thumbnail={P52}
                                    width="2882"
                                    height="1921"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P52} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P53}
                                    thumbnail={P53}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P53} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>


                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P54}
                                    thumbnail={P54}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P54} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P55}
                                    thumbnail={P55}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P55} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P56}
                                    thumbnail={P56}
                                    width="2882"
                                    height="1921"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P56} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P57}
                                    thumbnail={P57}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P57} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>      


                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P58}
                                    thumbnail={P58}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P58} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P59}
                                    thumbnail={P59}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P59} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P60}
                                    thumbnail={P60}
                                    width="2882"
                                    height="1921"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P60} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <Item
                                    original={P30}
                                    thumbnail={P30}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage" src={P30} alt=""/>
                                    )}
                                </Item>
                               
                            </Col>
                        </Row>             


                         <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P62}
                                    thumbnail={P62}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P62} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <Item
                                    original={P33}
                                    thumbnail={P33}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P33} alt=""/>
                                    )}
                                </Item>
                                {/* <Item
                                    original={P63}
                                    thumbnail={P63}
                                    width="1277"
                                    height="1916"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P63} alt=""/>
                                    )}
                                </Item> */}
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P64}
                                    thumbnail={P64}
                                    width="2882"
                                    height="1921"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P64} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P65}
                                    thumbnail={P65}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P65} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>       


                         <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P66}
                                    thumbnail={P66}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P66} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P67}
                                    thumbnail={P67}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P67} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P68}
                                    thumbnail={P68}
                                    width="2882"
                                    height="1921"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P68} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P69}
                                    thumbnail={P69}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P69} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>      


                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P70}
                                    thumbnail={P70}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P70} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P71}
                                    thumbnail={P71}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P71} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P72}
                                    thumbnail={P72}
                                    width="2882"
                                    height="1921"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P72} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P73}
                                    thumbnail={P73}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P73} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row> 


                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P74}
                                    thumbnail={P74}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P74} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P75}
                                    thumbnail={P75}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P75} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P76}
                                    thumbnail={P76}
                                    width="2882"
                                    height="1921"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P76} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P77}
                                    thumbnail={P77}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P77} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>   


                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P78}
                                    thumbnail={P78}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P78} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P79}
                                    thumbnail={P79}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P79} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P80}
                                    thumbnail={P80}
                                    width="2882"
                                    height="1921"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P80} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P81}
                                    thumbnail={P81}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P81} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row> 


                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P82}
                                    thumbnail={P82}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P82} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P83}
                                    thumbnail={P83}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P83} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P84}
                                    thumbnail={P84}
                                    width="2882"
                                    height="1921"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P84} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P85}
                                    thumbnail={P85}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P85} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>   


                        <Row className="firstImage">
                        <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <Item
                                    original={P14}
                                    thumbnail={P14}
                                    width="2882"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P14} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P87}
                                    thumbnail={P87}
                                    width="2882"
                                    height="1921"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P87} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            </Col>
                        </Row> 
                        </Gallery>
                    </div>
                </Container>
            </section>
        </div>
    )
}

export default dayOne
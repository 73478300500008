import React from 'react';
import './DayTwo.css';
import { Container, Button, Modal,Row, Col } from "react-bootstrap";
import { propTypes } from 'react-bootstrap/esm/Image';
import arrow from '../Assets/arrow.svg';

import P01 from '../Assets/dayTwo/01.png';
import P02 from '../Assets/dayTwo/02.png';
import P03 from '../Assets/dayTwo/03.png';
import P04 from '../Assets/dayTwo/04.png';
import P05 from '../Assets/dayTwo/05.png';
import P06 from '../Assets/dayTwo/06.png';
import P07 from '../Assets/dayTwo/07.png';
import P08 from '../Assets/dayTwo/08.png';
import P09 from '../Assets/dayTwo/09.png';
import P10 from '../Assets/dayTwo/10.png';
import P11 from '../Assets/dayTwo/11.png';
import P12 from '../Assets/dayTwo/12.png';
import P13 from '../Assets/dayTwo/13.png';
import P14 from '../Assets/dayTwo/14.png';
import P15 from '../Assets/dayTwo/15.png';
import P16 from '../Assets/dayTwo/16.png';
import P17 from '../Assets/dayTwo/17.png';
import P18 from '../Assets/dayTwo/18.png';
import P19 from '../Assets/dayTwo/19.png';
import P20 from '../Assets/dayTwo/20.png';
import P21 from '../Assets/dayTwo/21.png';
import P22 from '../Assets/dayTwo/22.png';
import P23 from '../Assets/dayTwo/23.png';
import P24 from '../Assets/dayTwo/24.png';
import P25 from '../Assets/dayTwo/25.png';
import P26 from '../Assets/dayTwo/26.png';
import P27 from '../Assets/dayTwo/27.png';
import P28 from '../Assets/dayTwo/28.png';
import P29 from '../Assets/dayTwo/29.png';
import P30 from '../Assets/dayTwo/30.png';
import P31 from '../Assets/dayTwo/31.png';
import P32 from '../Assets/dayTwo/32.png';
import P33 from '../Assets/dayTwo/33.png';
import P34 from '../Assets/dayTwo/34.png';
import P35 from '../Assets/dayTwo/35.png';
import P36 from '../Assets/dayTwo/36.png';
import P37 from '../Assets/dayTwo/37.png';
import P38 from '../Assets/dayTwo/38.png';
import P39 from '../Assets/dayTwo/39.png';
import P40 from '../Assets/dayTwo/40.png';
import P41 from '../Assets/dayTwo/41.png';
import P42 from '../Assets/dayTwo/42.png';
import P43 from '../Assets/dayTwo/43.png';
import P44 from '../Assets/dayTwo/44.png';
import P45 from '../Assets/dayTwo/45.png';
import P46 from '../Assets/dayTwo/46.png';
import P47 from '../Assets/dayTwo/47.png';
import P48 from '../Assets/dayTwo/48.png';
import P49 from '../Assets/dayTwo/49.png';
import P50 from '../Assets/dayTwo/50.png';
import P51 from '../Assets/dayTwo/51.png';
import P52 from '../Assets/dayTwo/52.png';
import P53 from '../Assets/dayTwo/53.png';
import P54 from '../Assets/dayTwo/54.png';
import P55 from '../Assets/dayTwo/55.png';
import P56 from '../Assets/dayTwo/56.png';
import P57 from '../Assets/dayTwo/57.png';
import P58 from '../Assets/dayTwo/58.png';
import P59 from '../Assets/dayTwo/59.png';
import P60 from '../Assets/dayTwo/60.png';
import P61 from '../Assets/dayTwo/61.png';
import P62 from '../Assets/dayTwo/62.png';
import P63 from '../Assets/dayTwo/63.png';
import P64 from '../Assets/dayTwo/64.png';
import P65 from '../Assets/dayTwo/65.png';
import P66 from '../Assets/dayTwo/66.png';
import P67 from '../Assets/dayTwo/67.png';
import P68 from '../Assets/dayTwo/68.png';
import P69 from '../Assets/dayTwo/69.png';
import P70 from '../Assets/dayTwo/70.png';
import P71 from '../Assets/dayTwo/71.png';
import P72 from '../Assets/dayTwo/72.png';
import P73 from '../Assets/dayTwo/73.png';
import P74 from '../Assets/dayTwo/74.png';
import P75 from '../Assets/dayTwo/75.png';
import P76 from '../Assets/dayTwo/76.png';
import P77 from '../Assets/dayTwo/77.png';
import P78 from '../Assets/dayTwo/78.png';
import P79 from '../Assets/dayTwo/79.png';
import P80 from '../Assets/dayTwo/80.png';
import P81 from '../Assets/dayTwo/81.png';
import P82 from '../Assets/dayTwo/82.png';
import P83 from '../Assets/dayTwo/83.png';
import P84 from '../Assets/dayTwo/84.png';
import P85 from '../Assets/dayTwo/85.png';
import P86 from '../Assets/dayTwo/86.png';
import P87 from '../Assets/dayTwo/87.png';
import P88 from '../Assets/dayTwo/88.png';
import P89 from '../Assets/dayTwo/89.png';
import P90 from '../Assets/dayTwo/90.png';
import P91 from '../Assets/dayTwo/91.png';
import P92 from '../Assets/dayTwo/92.png';
import P93 from '../Assets/dayTwo/93.png';
import P94 from '../Assets/dayTwo/94.png';
import P95 from '../Assets/dayTwo/95.png';
import P96 from '../Assets/dayTwo/96.png';
import P97 from '../Assets/dayTwo/97.png';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import { Gallery, Item } from 'react-photoswipe-gallery';

function DayTwo() {
   
    return (
        <div className='bgStyle'>
            {/* <div className='HomeButton'>
                <a href='/' className='styleBtnhme'>
                    <img src={arrow} className="arrowStyle"></img>
                    Home</a>
            </div> */}
            <section className='spaceBottom'>
                <Container fluid>
                    <div>
                        <p className='txtCard2Center'>Pune Day 2 Photos</p>
                    </div>
                    <div>
                        <Gallery shareButton={false}>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <Item
                                    original={P92}
                                    thumbnail={P92}
                                    width="1920"
                                    height="1280"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P92} alt=""/>
                                    )}
                                </Item>
                                
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P91}
                                    thumbnail={P91}
                                    width="1282"
                                    height="1920"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P91} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P03}
                                    thumbnail={P03}
                                    width="1920"
                                    height="1281"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P03} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P04}
                                    thumbnail={P04}
                                    width="1920"
                                    height="1281"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage" src={P04} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>

                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P05}
                                    thumbnail={P05}
                                    width="1920"
                                    height="1281"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P05} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P06}
                                    thumbnail={P06}
                                    width="1920"
                                    height="1281"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM " src={P06} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P08}
                                    thumbnail={P08}
                                    width="1920"
                                    height="1281"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P08} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P09}
                                    thumbnail={P09}
                                    width="1920"
                                    height="1281"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage" src={P09} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>

                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P10}
                                    thumbnail={P10}
                                    width="1920"
                                    height="1281"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P10} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <Item
                                    original={P14}
                                    thumbnail={P14}
                                    width="1920"
                                    height="1281"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P14} alt=""/>
                                    )}
                                </Item>
                                
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P12}
                                    thumbnail={P12}
                                    width="1920"
                                    height="1281"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P12} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P13}
                                    thumbnail={P13}
                                    width="1920"
                                    height="1281"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage" src={P13} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>

                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <Item
                                    original={P11}
                                    thumbnail={P11}
                                    width="1281"
                                    height="1920"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P11} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P15}
                                    thumbnail={P15}
                                    width="1282"
                                    height="1920"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P15} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P16}
                                    thumbnail={P16}
                                    width="1282"
                                    height="1920"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P16} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <Item
                                    original={P20}
                                    thumbnail={P20}
                                    width="1282"
                                    height="1920"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage" src={P20} alt=""/>
                                    )}
                                </Item>
                               
                            </Col>
                        </Row>

                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P18}
                                    thumbnail={P18}
                                    width="1920"
                                    height="1281"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P18} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P19}
                                    thumbnail={P19}
                                    width="1920"
                                    height="1281"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P19} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <Item
                                    original={P17}
                                    thumbnail={P17}
                                    width="1920"
                                    height="1281"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage" src={P17} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P21}
                                    thumbnail={P21}
                                    width="1920"
                                    height="1281"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P21} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>


                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P22}
                                    thumbnail={P22}
                                    width="1920"
                                    height="1281"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P22} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P23}
                                    thumbnail={P23}
                                    width="1920"
                                    height="1281"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P23} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P24}
                                    thumbnail={P24}
                                    width="1920"
                                    height="1281"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage" src={P24} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P25}
                                    thumbnail={P25}
                                    width="1920"
                                    height="1281"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P25} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>

                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P26}
                                    thumbnail={P26}
                                    width="1920"
                                    height="1281"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P26} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P27}
                                    thumbnail={P27}
                                    width="1920"
                                    height="1281"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P27} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <Item
                                    original={P30}
                                    thumbnail={P30}
                                    width="1920"
                                    height="1281"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P30} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P29}
                                    thumbnail={P29}
                                    width="1920"
                                    height="1281"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P29} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>

                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <Item
                                    original={P28}
                                    thumbnail={P28}
                                    width="1281"
                                    height="1920"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P28} alt=""/>
                                    )}
                                </Item>
                                
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P31}
                                    thumbnail={P31}
                                    width="1281"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P31} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <Item
                                    original={P47}
                                    thumbnail={P47}
                                    width="1280"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P47} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P33}
                                    thumbnail={P33}
                                    width="1281"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P33} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>

                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P34}
                                    thumbnail={P34}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P34} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P35}
                                    thumbnail={P35}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P35} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P36}
                                    thumbnail={P36}
                                    width="1920"
                                    height="1280"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P36} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P37}
                                    thumbnail={P37}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P37} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>

                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P38}
                                    thumbnail={P38}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P38} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P39}
                                    thumbnail={P39}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P39} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P40}
                                    thumbnail={P40}
                                    width="1920"
                                    height="1280"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P40} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P41}
                                    thumbnail={P41}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P41} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>

                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P42}
                                    thumbnail={P42}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P42} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P43}
                                    thumbnail={P43}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P43} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P44}
                                    thumbnail={P44}
                                    width="1920"
                                    height="1280"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P44} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P45}
                                    thumbnail={P45}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P45} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>

                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <Item
                                    original={P90}
                                    thumbnail={P90}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P90} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <Item
                                    original={P32}
                                    thumbnail={P32}
                                    width="1920"
                                    height="1281"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P32} alt=""/>
                                    )}
                                </Item>
                                
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P48}
                                    thumbnail={P48}
                                    width="1920"
                                    height="1080"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P48} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P49}
                                    thumbnail={P49}
                                    width="1280"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P49} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>   


                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P50}
                                    thumbnail={P50}
                                    width="1280"
                                    height="1921"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P50} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P51}
                                    thumbnail={P51}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P51} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P52}
                                    thumbnail={P52}
                                    width="1920"
                                    height="1280"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P52} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P53}
                                    thumbnail={P53}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P53} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>


                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P54}
                                    thumbnail={P54}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P54} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P55}
                                    thumbnail={P55}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P55} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P56}
                                    thumbnail={P56}
                                    width="1920"
                                    height="1280"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P56} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P57}
                                    thumbnail={P57}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P57} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>      


                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P58}
                                    thumbnail={P58}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P58} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P59}
                                    thumbnail={P59}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P59} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P60}
                                    thumbnail={P60}
                                    width="1920"
                                    height="1280"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P60} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P61}
                                    thumbnail={P61}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P61} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>             


                         <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P62}
                                    thumbnail={P62}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P62} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P63}
                                    thumbnail={P63}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P63} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P64}
                                    thumbnail={P64}
                                    width="1920"
                                    height="1280"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P64} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P65}
                                    thumbnail={P65}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P65} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>       


                         <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P66}
                                    thumbnail={P66}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P66} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P67}
                                    thumbnail={P67}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P67} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P68}
                                    thumbnail={P68}
                                    width="1920"
                                    height="1280"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P68} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P69}
                                    thumbnail={P69}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P69} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>      


                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P70}
                                    thumbnail={P70}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P70} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P71}
                                    thumbnail={P71}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P71} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P72}
                                    thumbnail={P72}
                                    width="1920"
                                    height="1280"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P72} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P73}
                                    thumbnail={P73}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P73} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row> 


                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P74}
                                    thumbnail={P74}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P74} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P75}
                                    thumbnail={P75}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P75} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P76}
                                    thumbnail={P76}
                                    width="1920"
                                    height="1280"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P76} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P77}
                                    thumbnail={P77}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P77} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>   


                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P78}
                                    thumbnail={P78}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P78} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P79}
                                    thumbnail={P79}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P79} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P80}
                                    thumbnail={P80}
                                    width="1920"
                                    height="1280"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P80} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P81}
                                    thumbnail={P81}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P81} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row> 


                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P82}
                                    thumbnail={P82}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P82} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P83}
                                    thumbnail={P83}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P83} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P84}
                                    thumbnail={P84}
                                    width="1920"
                                    height="1280"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P84} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P85}
                                    thumbnail={P85}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage" src={P85} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row>   


                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <Item
                                    original={P89}
                                    thumbnail={P89}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P89} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P86}
                                    thumbnail={P86}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P86} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P87}
                                    thumbnail={P87}
                                    width="1920"
                                    height="1280"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P87} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <Item
                                    original={P88}
                                    thumbnail={P88}
                                    width="1920"
                                    height="1280"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage" src={P88} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row> 


                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <Item
                                    original={P46}
                                    thumbnail={P46}
                                    width="1280"
                                    height="1920"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P46} alt=""/>
                                    )}
                                </Item>
                            
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P02}
                                    thumbnail={P02}
                                    width="1280"
                                    height="1920"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P02} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <Item
                                    original={P01}
                                    thumbnail={P01}
                                    width="1282"
                                    height="1920"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P01} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <Item
                                    original={P93}
                                    thumbnail={P93}
                                    width="1280"
                                    height="1920"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage" src={P93} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row> 


                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <Item
                                    original={P94}
                                    thumbnail={P94}
                                    width="1280"
                                    height="1920"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P94} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P95}
                                    thumbnail={P95}
                                    width="1280"
                                    height="1920"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P95} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <Item
                                    original={P96}
                                    thumbnail={P96}
                                    width="1280"
                                    height="1920"
                                    
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage spaceBottomM" src={P96} alt=""/>
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <Item
                                    original={P97}
                                    thumbnail={P97}
                                    width="1280"
                                    height="1920"
                                >
                                    {({ ref, open }) => (
                                    <img ref={ref} onClick={open} className="styleImage" src={P97} alt=""/>
                                    )}
                                </Item>
                            </Col>
                        </Row> 
                        </Gallery>
                    </div>
                </Container>
            </section>
        </div>
    )
}

export default DayTwo